import { useEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "../../feature/Spinner";
import ProductSelector from "../../feature/shop/ProductSelector";
import Translated from "../../feature/Translated";
import Button from "react-bootstrap/Button";
import ContactForm from "../../feature/ContactForm";
import ChatBubble from "../../feature/ChatBubble";
import Collapse from "react-bootstrap/Collapse";
import ActivityCount from "../../feature/ActivityCount";
import TrackerAB from "../../../utils/trackerAB";
import ImagesComponent from "../../feature/shop/ImagesComponent";
import { PRODUCT_PAGE_ID } from "./ProductPage";
import OtherProductCategories from "../../feature/shop/OtherShopCategories";
import ShopPaymentTermsNote from "../../feature/shop/ShopPaymentTermsNote";
import ShopCancellingTermsNote from "../../feature/shop/ShopCancellingTermsNote";
import usePendingOrderCheck from "../../../hooks/usePendingOrderCheck";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../feature/TranslationProvider";
import { useGlobalData } from "../../../context/GlobalContext";
import useProductLoader from "../../../hooks/useProductLoader";

const SUB_CATEGORY = "m";
const PRODUCTS_CATEGORY = "teaching_permit";

const MopedPage = () => {
  usePendingOrderCheck();
  const { setPageData } = useGlobalData();
  const { lang } = useTranslation();
  const navigate = useNavigate();
  const { isLoadingProducts, products, productsError } = useProductLoader(
    PRODUCTS_CATEGORY,
    SUB_CATEGORY
  );
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
  const [showPaymentTypes, setShowPaymentTypes] = useState(true);
  const [paymentBannerUrl, setPaymentBannerUrl] = useState("");
  const [showImagePromo, setShowImagePromo] = useState(false);
  const paymentBannerTimeoutRef = useRef(null);
  const imagePromoTimeoutRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    TrackerAB.track("webshop-shown");
    paymentBannerTimeoutRef.current = setTimeout(
      () =>
        setPaymentBannerUrl(
          "https://www.paytrail.com/hs-fs/hub/335946/file-493287103.png?width=300&name=file-493287103.png"
        ),
      4000
    );
    imagePromoTimeoutRef.current = setTimeout(
      () => setShowImagePromo(true),
      5000
    );

    return () => {
      clearTimeout(paymentBannerTimeoutRef.current);
      clearTimeout(imagePromoTimeoutRef.current);
    };
  }, []);

  const selectProduct = (product) => {
    setPageData(PRODUCT_PAGE_ID, product);
    navigate("/tuoteinfo");
  };

  const renderInfo = (key) => {
    return (
      <div className="blue-inner-box tight">
        <Translated translationKey={key} html={true} />
      </div>
    );
  };

  const renderDemoLink = () => {
    return (
      <div className="centered semi-dark-inner-box tight fade-in delay top-margin-small">
        <Button
          bsstyle="warning"
          aria-label="Open image test demo"
          role="presentation"
          onClick={() => {
            TrackerAB.track("webshop-demo");
            navigate("/demo/images/m");
          }}
        >
          <Translated translationKey="start_demo" />
        </Button>
      </div>
    );
  };

  const renderContactForm = () => {
    const buttonKey = showContactForm ? "cancel" : "contact";
    let content;
    if (showContactForm) {
      content = (
        <div className="top-margin contact-wrapper white-box slide-in">
          <ContactForm
            email
            onSubmitted={() => {
              setContactFormSubmitted(true);
              setShowContactForm(false);
            }}
          />
        </div>
      );
    } else if (contactFormSubmitted) {
      content = <Translated translationKey="message_sent" />;
    }

    return (
      <div className="large-top-margin webshop-contact-wrapper">
        <Button
          aria-label="Send feedback"
          role="presentation"
          onClick={() => {
            setShowContactForm((prev) => !prev);
            setContactFormSubmitted(false);
          }}
        >
          <Translated translationKey={buttonKey} />
        </Button>
        {content}
      </div>
    );
  };

  const renderProducts = () => {
    let content;
    if (productsError) {
      content = (
        <Alert bsstyle="danger">
          <Translated translationKey={productsError} />
        </Alert>
      );
    } else if (isLoadingProducts) {
      content = (
        <div>
          <Spinner />
        </div>
      );
    } else if (products) {
      content = (
        <ProductSelector products={products} onSelect={selectProduct} />
      );
    }
    return (
      <div className="medium-top-margin medium-bottom-margin">{content}</div>
    );
  };

  const renderChatBubblesMobile = () => {
    if (showContactForm) {
      return null;
    }

    return (
      <div className="medium-top-margin medium-bottom-margin">
        <div>
          <ChatBubble>
            <p>
              <span>Kiitos meni ekalla läpi hyvä sivusto :)</span>
              <br />
              <i>Eemeli Jokinen, 17.4.2019</i>
            </p>
          </ChatBubble>
        </div>
      </div>
    );
  };

  const renderChatBubbles = () => {
    if (showContactForm) {
      return null;
    }

    return (
      <div className="medium-top-margin medium-bottom-margin fade-in longer-delay">
        <div>
          <ChatBubble>
            <p>
              <span>
                Mahtava sivusto! Teoriakoe meni ekalla läpi, suosittelen
                kovasti!
              </span>
              <br />
              <i>Daniel Määttä, 14.8.2020</i>
            </p>
          </ChatBubble>
          <div className="pull-right">
            <ChatBubble>
              <p>
                <span>Kiitos meni ekalla läpi hyvä sivusto :)</span>
                <br />
                <i>Eemeli Jokinen, 17.4.2019</i>
              </p>
            </ChatBubble>
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentBanner = () => {
    if (showContactForm) {
      return null;
    }

    const banner = (
      <div className="semi-dark-inner-box tight fade-in delay top-margin-small">
        {showPaymentTypes ? null : (
          <div className="centered">
            <Button
              bsstyle="link"
              aria-label="Show payment types"
              role="presentation"
              onClick={() => {
                TrackerAB.track("webshop-show-payment-types");
                setShowPaymentTypes(true);
              }}
            >
              <Translated translationKey="payment_types" />
              ...
            </Button>
          </div>
        )}
        <Collapse in={showPaymentTypes}>
          <div className="top-margin">
            {
              <div className="centered fade-in">
                <Translated translationKey="payment_types" className="strong" />
                <br />
                {paymentBannerUrl && (
                  <img
                    src={paymentBannerUrl}
                    style={{ width: 120, height: 120 }}
                    className="fade-in slide-in"
                  />
                )}
                <br />
                <Translated translationKey="payment_operator_info" />
                <ShopPaymentTermsNote /> <ShopCancellingTermsNote />
              </div>
            }
          </div>
        </Collapse>
      </div>
    );

    return banner;
  };

  const renderPromoImages = () => {
    if (!showImagePromo) {
      return null;
    }
    return (
      <div className="semi-dark-inner-box tight centered fade-in">
        <div className="images-promo-on-page centered">
          <ImagesComponent config={{ setId: "generic" }} />
        </div>
      </div>
    );
  };

  const renderCompany = () => {
    return (
      <div
        className="centered large-top-margin semi-transparent touchable"
        onClick={() => navigate("/privacy-policy")}
      >
        <strong>Datadrivers Oy</strong>
        <br />
        Hallituskatu 2 A 7
        <br />
        95400 Tornio
      </div>
    );
  };

  const renderActivity = () => {
    return (
      <div className="centered semi-dark-inner-box tight fade-in delay top-margin-small">
        <ActivityCount type="large" />
      </div>
    );
  };

  const renderActivity2 = () => {
    return (
      <div className="fade-in delay medium-top-margin pull-right phone-centered">
        <ActivityCount type="large" className="phone-centered" />
      </div>
    );
  };

  const renderMonolithInfo = () => {
    return (
      <div id="shop-page" className="page-container max-width-container">
        <h1>
          <Translated translationKey="webshop_moped_header" />
        </h1>
        <div className="hide-sm-and-gt">{renderChatBubblesMobile()}</div>
        {renderActivity()}
        {renderInfo("webshop_moped_info")}
        {renderDemoLink()}
        {renderProducts()}
        <OtherProductCategories />
        {renderPaymentBanner()}
        {renderPromoImages()}
        <div className="hide-xs">{renderChatBubbles()}</div>
        {renderContactForm()}
        {renderCompany()}
      </div>
    );
  };

  const renderSplittedInfo = () => {
    return (
      <div id="shop-page" className="page-container max-width-container">
        <div className="vertical-middle on-sides phone-column">
          <h1 className="phone-centered">
            <Translated translationKey="webshop_moped_header" />
          </h1>
          {renderActivity2()}
        </div>
        {renderInfo("webshop_moped_info_a")}
        {renderDemoLink()}
        {renderProducts()}
        <OtherProductCategories />
        {renderInfo("webshop_money_back")}
        <div className="hide-sm-and-gt">{renderChatBubblesMobile()}</div>
        <div className="vertical-middle phone-column">
          {renderInfo("webshop_moped_info_b")}
          {renderPromoImages()}
        </div>
        {renderPaymentBanner()}
        <div className="hide-xs">{renderChatBubbles()}</div>
        {renderDemoLink()}
        <div className="vertical-middle even phone-column">
          {renderContactForm()}
          {renderCompany()}
        </div>
      </div>
    );
  };

  if (lang !== "fi") {
    return renderMonolithInfo();
  }
  return renderSplittedInfo();
};

export default MopedPage;

import React from 'react';
import Button from 'react-bootstrap/Button';
import {useCallTestCrashBackend} from "../../api-new";

const VersionPage = (props) => {
  const callTestCrashBackend = useCallTestCrashBackend();

  return (
      <div>
        Build date: {BUILD_VERSION}
        <br/>
        App version: {APP_VERSION}
        <br/>
        Production build: {IS_PRODUCTION ? 'YES' : 'NO'}
        <div className="top-margin">
          <Button onClick={(event) => {
            console.log('Intentionally crashing for testing purposes!');
            setTimeout(() => {
              event.thisFunctionCrashesIntentionally();
            }, 500);
          }}>
            Test crash frontend
          </Button>
          {props.isAdmin &&
            (
              <div className={'top-margin-small'}>
              <Button onClick={(event) => {callTestCrashBackend.refetch()}}>
                Test crash backend
              </Button>
              </div>
            )
          }
        </div>
      </div>
  );
}

export default VersionPage


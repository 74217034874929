import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import Tracker from "./utils/tracker";
import Logger from "./utils/logger";
import queryString from "query-string";

const API_VERSION = "/api/v1";
const BASE_URL = "" + API_VERSION;
const HTTP_TIMEOUT_MS = 30000;
const MAX_FAIL_COUNT = 3;

// Login
const LOGIN_EMAIL_URL = BASE_URL + "/login/email";
const LOGIN_ONE_TIME_KEY = BASE_URL + "/login/one-time-key";
const LOGIN_AAINTEGRATION_URL = BASE_URL + "/login/aaintegration";
const LOGIN_AATEORIAINTEGRATION_URL = BASE_URL + "/login/aateoriaintegration";
const LOGIN_AUTOLIITTO_SSO_URL = BASE_URL + "/login/autoliitto/sso";
const LOGIN_AUTOLIITTO_URL = BASE_URL + "/login/autoliitto";

const LOG_DIRECT_TOKEN_LOGIN = BASE_URL + "/login/log-direct-token-login";

// Common
const CONTACT_TRIAL = BASE_URL + "/contact/trial";
const LOCALIZATIONS_URL = BASE_URL + "/localizations/:lang";
const SETTINGS_URL = BASE_URL + "/user-settings";
const PROFILE_URL = BASE_URL + "/profile/:userId";
const CATEGORY_STATUS_URL = BASE_URL + "/category-status/:userId/:category";
const BOOK_STATUS_URL = BASE_URL + "/book-status/:userId/:lang";
const BOOK_INDEX_URL = BASE_URL + "/book-index/:bookId/:lang";
const BOOK_CONTENT_URL = BASE_URL + "/book-content/:contentId/:lang";
const BOOK_CONTENT_STATUS_URL = BASE_URL + "/book-content-status";
const TEST_CREATE_URL = BASE_URL + "/test-create/:category/:lang";
const TEST_ANSWER_URL = BASE_URL + "/test-answer/:testId";
const TEST_RESULT_URL = BASE_URL + "/test-result/:resultId/:lang";
const PRACTICE_LITERALS_URL =
  BASE_URL + "/test-practice-literals/:category/:lang";
const PRACTICE_LOG_URL = BASE_URL + "/practice-log";
const PRACTICE_TYPES_URL = BASE_URL + "/practice-types/:category";
const DEMO_IMAGES_URL = BASE_URL + "/demo-practice-images/:category/:lang";
const PRACTICE_IMAGES_URL = BASE_URL + "/test-practice-images/:category/:lang";
const PRACTICE_SIGNS_URL = BASE_URL + "/test-practice-signs/:lang";
const USER_EMAIL_REQUEST_EMAIL_RECOVERY =
  BASE_URL + "/user-email-request/recovery/:lang";
const USER_EMAIL_REQUEST_ACTION =
  BASE_URL + "/user-email-request/action/:dataToken";
const SEND_FEEDBACK = BASE_URL + "/messaging/feedback";
const TEST_RESULTS_URL = BASE_URL + "/test-results/:userId/";
const NEWS_URL = BASE_URL + "/news/:lang";
const REPORT_QUESTION_URL = BASE_URL + "/report/question";
const ACTIVITY_COUNT_URL = BASE_URL + "/activity-count";
const USER_EMAIL_REQUEST_SET_PASSWORD =
  BASE_URL + "/user-email-request/set-password/:dataToken";
const AGREEMENT_CONTENT_URL = BASE_URL + "/agreement/:name/:version/:lang";
const ACCEPT_AGREEMENT_URL = BASE_URL + "/agreement/sign";

// Teacher
const LATEST_ACCEPTED_USERS_URL =
  BASE_URL + "/search/latest-accepted/:schoolId";
const SEARCH_USERS_URL = BASE_URL + "/search/user/:term";
const LIST_NEWEST_USERS = BASE_URL + "/search/newest-added/:schoolId";
const LIST_NEWEST_ACTIVATED_USERS =
  BASE_URL + "/search/newest-activated/:schoolId";
const ALL_ACTIVE_STUDENTS = BASE_URL + "/search/active-students/:schoolId";
const ADD_TEACHER_WITH_EMAIL = BASE_URL + "/school/:schoolId/teacher";
const ADD_USER_WITH_EMAIL = BASE_URL + "/user-email-request/create/:schoolId";
const USER_EMAIL_REQUEST_PENDING_CREATES =
  BASE_URL + "/user-email-request/pending-creates/:schoolId";
const SCHOOL_TEACHERS = BASE_URL + "/school/:schoolId/teacher";
const EMAIL_ONE_TIME_KEYS = BASE_URL + "/email-one-time-keys";
const ONE_TIME_KEY_ORDERS_URL = BASE_URL + "/orders/one-time-keys/:schoolId";
const SCHOOL_DATA_URL = BASE_URL + "/school/:schoolId";
const SCHOOL_CATEGORY_SETTINGS_URL =
  BASE_URL + "/school/category-settings/:schoolId";
const USER_CONNECT_EMAIL =
  BASE_URL + "/user-email-request/connect/:userId/:lang";
const CONNECT_EMAIL = BASE_URL + "/user-email-request/connect/:lang";
const SEND_POLL_ANSWER = BASE_URL + "/poll-answer";
const SEARCH_USERS_ADMIN = BASE_URL + "/admin/search/user";

// Shop
const PRODUCTS_URL = BASE_URL + "/shop/products/:category/:subCategory/:lang";
const PURCHASE_URL = BASE_URL + "/shop/purchase";
const WEBSHOP_ORDER_URL = BASE_URL + "/shop/order/:orderId/:token";
const EMAIL_VERIFICATION_URL = BASE_URL + "/shop/verify-email/:email";
const SUB_CATEGORIES_URL = BASE_URL + "/shop/subcategories/:category";
const WEBSHOP_ORDER_UPDATE_PAYMENT_METHOD_URL =
  BASE_URL + "/shop/order/:orderId/:token/:methodId";

// Admin
const CLEAR_CACHES_URL = BASE_URL + "/admin/caches-clear";
const SCHOOLS_SEARCH = BASE_URL + "/admin/search/schools";
const SCHOOL_COMMENTS = BASE_URL + "/admin/school-comments/:schoolId";
const EVENT_LOGS =
  BASE_URL +
  "/admin/event-logs?page=:page&term=:term&targetId=:targetId&targetType=:targetType";
const LIST_INVOICES = BASE_URL + "/admin/list-invoices";
const CHANGE_EMAIL = BASE_URL + "/admin/user/:userId/change-email";
const DISCONNECT_EMAIL = BASE_URL + "/admin/user/:userId/disconnect-email";
const REMOVE_EMAIL_PASSWORD =
  BASE_URL + "/admin/user/:userId/remove-email-password";
const SEARCH_USER_SUB_PERIODS =
  BASE_URL + "/admin/user/subscription-periods/:userId";
const SEARCH_USER_LICENSES = BASE_URL + "/admin/user-licenses/:userId";
const REMOVE_LICENSE = BASE_URL + "/admin/user-licenses/:userId/remove";
const ADD_CATEGORY_LICENSE =
  BASE_URL + "/admin/user-licenses/:userId/category-license";
const ADD_MATERIAL_LICENSE =
  BASE_URL + "/admin/user-licenses/:userId/material-license";
const SEARCH_ORDERS = BASE_URL + "/admin/search/webshop-orders";
const ACCEPT_WEBSHOP_ORDER = BASE_URL + "/admin/order/:orderId/accept-order";
const EMAIL_EVENTS = BASE_URL + "/admin/search/email/email-events/:email";
const SEARCH_USER_LOGIN_LOGS = BASE_URL + "/admin/login-logs/:userId";
const USER_COMMENTS = BASE_URL + "/admin/user-comments/:userId";
const SCHOOLS_ADD = BASE_URL + "/admin/school/";
const GET_TOKEN_FOR_USER_ID =
  BASE_URL + "/admin/login-as-any-user/user-id/:userId";
const GET_TOKEN_FOR_USER_EMAIL =
  BASE_URL + "/admin/login-as-any-user/user-email/:email";
const LITERALS = BASE_URL + "/admin/questions/literals/:category/:lang?id=:id";
const BOOK_REFERENCE =
  BASE_URL +
  "/admin/book-reference/:questionType/:questionId/:category/:referenceId";
const IMAGES = BASE_URL + "/admin/questions/images/:category/:lang?id=:id";
const LIST_INVOICEABLE_PERIODS = BASE_URL + "/admin/list-invoiceable-periods";
const CREATE_INVOICES = BASE_URL + "/admin/create-invoices";
const PRECRETE_ONE_TIME_KEYS = BASE_URL + "/admin/one-time-keys-precreate";
const EXPORT_INVOICES = BASE_URL + "/admin/export-invoices";
const LITERAL = BASE_URL + "/admin/questions/literal/:id";
const TEST_CRASH_BACKEND = BASE_URL + "/admin/crashmemanually";

export let apiDecodedToken = null;
export let hasApiDecodedTokenData = false;
let logoutFn = null;
let setRequiredTos = null;
let updateTokenFn = null;
let setHasMaintenanceFn = null;
let setHasConnectionFn = null;

export const setApiDecodedToken = (decodedToken) => {
  apiDecodedToken = decodedToken;
  hasApiDecodedTokenData = true;
};

export const setApiLogoutFn = (logout) => {
  logoutFn = logout;
};

export const setApiRequiredTos = (setRequiredTosFn) => {
  setRequiredTos = setRequiredTosFn;
};

export const setApiUpdateTokenDataFn = (updateTokenDataFn) => {
  updateTokenFn = updateTokenDataFn;
};

export const setApiHasMaintenanceFn = (setHasMaintenance) => {
  setHasMaintenanceFn = setHasMaintenance;
};

export const setApiHasConnectionFn = (setHasConnection) => {
  setHasConnectionFn = setHasConnection;
};

const resolveErrorMessage = (error) => {
  let errorMessage = null;

  /** Parse error and try to take the error or message from the response data */

  if (error.message) {
    errorMessage = error.message;
  }

  if (error.response && error.response.status === 500) {
    //Not a correct error, should be replaced in server too.
    errorMessage = "unknown-error";
  }

  if (error.response && error.response.status === 503) {
    errorMessage = "maintenance";
  }

  if (error.response && error.response.data.message) {
    errorMessage = error.response.data.message;
  }

  if (error.response && error.response.data.error) {
    errorMessage = error.response.data.error;
  }

  /** Try to resolve a more defined (localized code) out of the message. */

  if (errorMessage === "Network Error") {
    errorMessage = "network-error";
    Tracker.logEvent("api", "Network error");
  }

  if (errorMessage === "Unexpected token < in JSON at position 0") {
    errorMessage = "invalid-response-format";
    Tracker.logEvent("api", "Invalid response format");
  }

  if (!!errorMessage && errorMessage.indexOf("timeout") === 0) {
    errorMessage = "request-timeout";
    Tracker.logEvent("api", "Network timeout");
  }

  /** Check if all parsing failed */

  if (
    errorMessage === null ||
    errorMessage === undefined ||
    errorMessage === ""
  ) {
    errorMessage = "unknown-error";
    Tracker.logEvent("api", "Unknown error");
  }

  if (typeof errorMessage !== "string") {
    errorMessage = "unknown-error";
    Tracker.logEvent("api", "Unknown error");
  }

  return errorMessage;
};

const resolveQuery = (data) => {
  if (data.body && !data.body.error) {
    return Promise.resolve(data.body);
  }

  // Status 'fail' and 'error' are sent to components as similar errors. Keep the rejected object in same format (most importantly message at top level).

  if (data.body && data.body.error) {
    // Automatic retry flag
    let shouldRetry = false;

    return Promise.reject({
      ...data.body,
      message: data.body.error,
      shouldRetry,
    });
  }

  // Status error and default behaviour
  return Promise.reject({
    ...data.body,
    message: data.body?.error || data.message, // Warning: If data contains message, this will override it!
    shouldRetry: true,
    _catchError: data._catchError,
    _url: data._url,
  });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failCount, error) => {
        if (!error.shouldRetry) {
          return false;
        }

        if (failCount === MAX_FAIL_COUNT) {
          if (error._catchError) {
            // For apiCall catch block errors
            // reportMessage("WaApi " + error._catchError.toString(), { error });
          } else {
            // For API errors
            // reportMessage("WA API error", { error });
          }
        }

        return failCount < MAX_FAIL_COUNT;
      },
      refetchOnWindowFocus: false,
    },
  },
});

const createAbortController = (time) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(`Timed out after ${time} ms`), time);

  return controller;
};

const createHeaders = () => {
  const token = apiDecodedToken?.encoded || null;
  const userId = apiDecodedToken?.userId || "anon";
  const profileSchoolId = apiDecodedToken?.schoolId || null;
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "user-id": userId,
    "profile-school-id": profileSchoolId,
    "app-version": APP_VERSION,
    "build-version": BUILD_VERSION,
  };
};

const apiCall = async (url, method, data, timeoutMs) => {
  const controller = createAbortController(timeoutMs);
  const init = {
    method,
    signal: controller.signal,
    headers: createHeaders(),
  };

  if (["POST", "PUT", "PATCH"].includes(method)) {
    init["body"] = JSON.stringify(data);
  }

  try {
    if (process.env.NODE_ENV === "development") {
      console.log(
        `${DateTime.now().toFormat(
          "HH:mm:ss"
        )} API call (${method.toUpperCase()}) ${url}`
      );
    }

    const response = await fetch(url, init);
    const httpStatusCode = response.status;
    const headers = response.headers;

    if (!response.ok) {
      let errorData;
      const contentType = headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        errorData = await response.json();
      } else {
        errorData = await response.text();
      }

      const error = new Error(`HTTP error ${response.status}`);
      error.response = {
        status: httpStatusCode,
        data: errorData,
      };

      throw error;
    }

    if (process.env.NODE_ENV === "development") {
      console.log(
        `${DateTime.now().toFormat(
          "HH:mm:ss"
        )} API response code ${httpStatusCode} to ${url}`
      );
    }

    // Server adds custom header if this user is required to accept new terms of service.
    const tosVersion = headers.get("x-required-tos");
    if (tosVersion && setRequiredTos) {
      setRequiredTos(tosVersion);
    }

    const token = headers.get("authorization");
    if (token) {
      // Token was updated from this api call, take new one into use.
      Logger.setUserData({ token });
      if (updateTokenFn) {
        updateTokenFn(token);
      }
    }

    const body = await response.json();

    return { body, httpStatusCode, _url: url };
  } catch (e) {
    if (e.response) {
      switch (e.response.status) {
        case 401:
          // Unauthorized, log out
          console.log("Request was unauthorized");
          if (logoutFn) {
            logoutFn();
          } else if (e.response.data.error === "old-app-version") {
            window.location.reload();
          }

          Tracker.logEvent("api", "Unauthorized");
          break;
        case 404:
          window.location.reload();
          break;
        case 503:
          // Server is in maintenance
          if (setHasMaintenanceFn) {
            setHasMaintenanceFn(true);
          }
          break;
      }
    } else {
      // No response, network maybe unavailable
      Tracker.logEvent("api", "Could not connect to server");
      if (setHasConnectionFn) {
        setHasConnectionFn(false);
      }
    }

    let message = resolveErrorMessage(e);

    if (process.env.NODE_ENV === "development") {
      console.warn("API error: " + e);
    }

    return { message, _catchError: e, _url: url };
  }
};

const get = (url, timeoutMs = HTTP_TIMEOUT_MS) => {
  return apiCall(url, "GET", null, timeoutMs);
};

const post = (url, data, timeoutMs = HTTP_TIMEOUT_MS) => {
  return apiCall(url, "POST", data, timeoutMs);
};

const put = (url, data, timeoutMs = HTTP_TIMEOUT_MS) => {
  return apiCall(url, "PUT", data, timeoutMs);
};

const patch = (url, data, timeoutMs = HTTP_TIMEOUT_MS) => {
  return apiCall(url, "PATCH", data, timeoutMs);
};

export const invalidateQueries = (...queryKey) => {
  queryClient.invalidateQueries({ queryKey });
};

export const resetQueries = (...queryKey) => {
  queryClient.resetQueries({ queryKey });
};

export const clearCaches = () => {
  queryClient.clear();
};

export const QUERY_KEY = {
  LOCALIZATIONS: "localizations",
  USER_SETTINGS: "userSettings",
  USER_PROFILE: "userProfile",
  CATEGORY_STATUSES: "categoryStatuses",
  BOOK_STATUSES: "bookStatuses",
  BOOK_INDEX: "bookIndex",
  FULLTEST: "fulltest",
  FULLTEST_RESULTS: "fullTestResults",
  BOOK_CONTENT: "bookContent",
  DEMO_IMAGES: "demoImages",
  PRACTICE_TYPES: "practiceTypes",
  LATEST_ACCEPTED_USERS: "latestAcceptedUsers",
  SEARCH_USERS: "searchUsers",
  LIST_STUDENTS: "listStudents",
  LIST_TEACHERS: "listTeachers",
  PENDING_CREATES: "pendingCreates",
  TEST_RESULT_OVERVIEW: "testResultOverview",
  LATEST_ONE_TIME_KEY_ORDER: "latestOneTimeKeyOrders",
  SCHOOL_DATA: "schoolData",
  SCHOOL_CATEGORY_SETTINGS: "schoolCategorySettings",
  NEWS: "news",
  ACTIVITY_COUNT: "activityCount",
  USER_EMAIL_REQ_ACTION: "userEmailReqAction",
  AGREEMENT_CONTENT: "agreementContent",
  PRODUCTS: "products",
  ORDER: "order",
  VERIFY_EMAIL: "verifyEmail",
  SUBCATEGORIES: "subCategories",
  VALIDATE_CODE: "validateCode",
  SCHOOL_COMMENTS: "schoolComments",
  EVENT_LOGS: "eventLogs",
  INVOICES: "invoices",
  SUBSCRIPTION_PERIODS: "subscriptionPeriods",
  USER_LICENSES: "userLicenses",
  WEBSHOP_ORDERS_EMAIL: "webshopOrdersEmail",
  EMAIL_EVENTS: "emailEvents",
  LOGIN_LOGS: "loginLogs",
  USER_COMMENTS: "userComments",
  SEARCH_USERS_ADMIN: "searchUsersAdmin",
  TOKEN_USER_ID_LOGIN: "tokenUserIdLogin",
  TOKEN_USER_EMAIL_LOGIN: "tokenUserEmailLogin",
  LIST_LITERAL_QUESTIONS: "listLiteralQuestions",
  LIST_IMAGE_QUESTIONS: "listImageQuestions",
  INVOICABLE_PERIODS: "invoiceablePeriods",
  SEARCH_WEBSHOP_ORDERS: "searchWebshopOrders",
};

export const useLocalizations = (language) => {
  return useQuery({
    queryKey: [QUERY_KEY.LOCALIZATIONS, language],
    queryFn: async () => {
      let url = LOCALIZATIONS_URL.replace(":lang", language.toLowerCase());

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!language,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

export const useUserSettings = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.USER_SETTINGS, userId],
    queryFn: async () => {
      const data = await get(SETTINGS_URL);
      return resolveQuery(data);
    },
    enabled: !!userId && !!apiDecodedToken,
  });
};

export const useUserProfile = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.USER_PROFILE, userId],
    queryFn: async () => {
      // Integration userIds have a fragment ("#" symbol in it), so encode the userId as fragments aren't sent to the server otherwise.
      let url = PROFILE_URL.replace(":userId", encodeURIComponent(userId));

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
  });
};

export const useCategoryStatuses = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.CATEGORY_STATUSES, userId],
    queryFn: async () => {
      let url = CATEGORY_STATUS_URL.replace(
        ":userId",
        encodeURIComponent(userId)
      );
      url = url.replace(":category", "");

      const data = await get(url);
      return resolveQuery(data);
    },
  });
};

export const useBookStatuses = (userId, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.BOOK_STATUSES, userId, lang],
    queryFn: async () => {
      let url = BOOK_STATUS_URL.replace(":userId", encodeURIComponent(userId));
      url = url.replace(":lang", lang);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
  });
};

export const useBookIndex = (bookId, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.BOOK_INDEX, bookId, lang],
    queryFn: async () => {
      let url = BOOK_INDEX_URL.replace(":bookId", bookId);
      url = url.replace(":lang", lang.toLowerCase());

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!bookId && !!lang,
  });
};

export const useFullTest = (category, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.FULLTEST, category, lang],
    queryFn: async () => {
      let url = TEST_CREATE_URL.replace(":category", category.toLowerCase());
      url = url.replace(":lang", lang.toLowerCase());

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: false,
  });
};

export const useFullTestResults = (resultId, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.FULLTEST_RESULTS, resultId, lang],
    queryFn: async () => {
      let url = TEST_RESULT_URL.replace(":resultId", resultId);
      url = url.replace(":lang", lang);

      console.log(url);
      const data = await get(url);
      return resolveQuery(data);
    },
  });
};

export const useBookContent = (contentId, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.BOOK_CONTENT, contentId, lang],
    queryFn: async () => {
      let url = BOOK_CONTENT_URL.replace(":contentId", contentId);
      url = url.replace(":lang", lang.toLowerCase());

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: false,
  });
};

export const usePracticeTypes = (category) => {
  return useQuery({
    queryKey: [QUERY_KEY.PRACTICE_TYPES, category],
    queryFn: async () => {
      let url = PRACTICE_TYPES_URL.replace(":category", category.toLowerCase());

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!category,
  });
};

export const useDemoImages = (category, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.DEMO_IMAGES, category, lang],
    queryFn: async () => {
      let url = DEMO_IMAGES_URL.replace(":category", category.toLowerCase());
      url = url.replace(":lang", lang.toLowerCase());
      Tracker.logEvent("api", "demo-images");

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: false,
  });
};

export const useLatestAcceptedUsers = (schoolId) => {
  return useQuery({
    queryKey: [QUERY_KEY.LATEST_ACCEPTED_USERS, schoolId],
    queryFn: async () => {
      const url = LATEST_ACCEPTED_USERS_URL.replace(":schoolId", schoolId);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!schoolId,
  });
};

export const useSearchUsers = (term) => {
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_USERS, term],
    queryFn: async () => {
      const url = SEARCH_USERS_URL.replace(":term", term);
      Tracker.logEvent("api", "search-users");

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!term,
  });
};

export const useListStudents = (schoolId, rule) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_STUDENTS, schoolId, rule],
    queryFn: async () => {
      let url = "";
      switch (rule) {
        case "latest-added-students":
          url = LIST_NEWEST_USERS;
          break;
        case "latest-activated-students":
          url = LIST_NEWEST_ACTIVATED_USERS;
          break;
        case "latest-accepted-students":
          url = LATEST_ACCEPTED_USERS_URL;
          break;
        case "all-active-students":
          url = ALL_ACTIVE_STUDENTS;
          break;
      }

      url = url.replace(":schoolId", schoolId);
      console.log("URL", url);
      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!schoolId && !!rule,
  });
};

export const usePendingUserCreates = (schoolId) => {
  return useQuery({
    queryKey: [QUERY_KEY.PENDING_CREATES, schoolId],
    queryFn: async () => {
      let url = USER_EMAIL_REQUEST_PENDING_CREATES.replace(
        ":schoolId",
        schoolId
      );

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!schoolId,
  });
};

export const useListTeachers = (schoolId) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_TEACHERS, schoolId],
    queryFn: async () => {
      const url = SCHOOL_TEACHERS.replace(":schoolId", schoolId);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!schoolId,
  });
};

export const useTestResultsOverview = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.TEST_RESULT_OVERVIEW, userId],
    queryFn: async () => {
      let url = TEST_RESULTS_URL.replace(":userId", encodeURIComponent(userId));

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
  });
};

export const useLatestOneTimeKeyOrders = (schoolId) => {
  return useQuery({
    queryKey: [QUERY_KEY.LATEST_ONE_TIME_KEY_ORDER, schoolId],
    queryFn: async () => {
      const url = ONE_TIME_KEY_ORDERS_URL.replace(":schoolId", schoolId);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!schoolId,
  });
};

export const useSchoolData = (schoolId) => {
  return useQuery({
    queryKey: [QUERY_KEY.SCHOOL_DATA, schoolId],
    queryFn: async () => {
      const url = SCHOOL_DATA_URL.replace(":schoolId", schoolId);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!schoolId,
  });
};

export const useSchoolCategorySettings = (schoolId) => {
  return useQuery({
    queryKey: [QUERY_KEY.SCHOOL_CATEGORY_SETTINGS, schoolId],
    queryFn: async () => {
      const url = SCHOOL_CATEGORY_SETTINGS_URL.replace(":schoolId", schoolId);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!schoolId,
  });
};

export const useNews = (language) => {
  return useQuery({
    queryKey: [QUERY_KEY.NEWS, language],
    queryFn: async () => {
      let url = NEWS_URL.replace(":lang", language);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!language,
  });
};

export const useActivityCount = () => {
  return useQuery({
    queryKey: [QUERY_KEY.ACTIVITY_COUNT],
    queryFn: async () => {
      const data = await get(ACTIVITY_COUNT_URL);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useAgreementContent = (name, version, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.AGREEMENT_CONTENT, name, version, lang],
    queryFn: async () => {
      let url = AGREEMENT_CONTENT_URL;
      url = url.replace(":name", name);
      url = url.replace(":version", version);
      url = url.replace(":lang", lang);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useUserEmailRequestAction = (dataToken) => {
  return useQuery({
    queryKey: [QUERY_KEY.USER_EMAIL_REQ_ACTION],
    queryFn: async () => {
      let url = USER_EMAIL_REQUEST_ACTION.replace(":dataToken", dataToken);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
    enabled: !!dataToken,
  });
};

export const useProducts = (category, subCategory, lang) => {
  return useQuery({
    queryKey: [QUERY_KEY.PRODUCTS, category, subCategory, lang],
    queryFn: async () => {
      let url = PRODUCTS_URL;
      url = url.replace(":category", category);
      url = url.replace(":subCategory", subCategory);
      url = url.replace(":lang", lang);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
    enabled: !!category && !!subCategory && !!lang,
  });
};

export const useOrder = (orderId, token) => {
  return useQuery({
    queryKey: [QUERY_KEY.ORDER, orderId, token],
    queryFn: async () => {
      let url = WEBSHOP_ORDER_URL;
      url = url.replace(":orderId", orderId);
      url = url.replace(":token", token);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
    enabled: !!orderId && !!token,
  });
};

export const useVerifyEmail = (email) => {
  return useQuery({
    queryKey: [QUERY_KEY.VERIFY_EMAIL, email],
    queryFn: async () => {
      let url = EMAIL_VERIFICATION_URL;
      url = url.replace(":email", email);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

export const useSubcategories = (category) => {
  return useQuery({
    queryKey: [QUERY_KEY.SUBCATEGORIES, category],
    queryFn: async () => {
      let url = SUB_CATEGORIES_URL.replace(":category", category);

      const data = await get(url);
      return resolveQuery(data);
    },
  });
};

export const usePracticeImages = () => {
  return useMutation({
    mutationFn: async ({ category, lang, seenIds }) => {
      let url = PRACTICE_IMAGES_URL.replace(
        ":category",
        category.toLowerCase()
      );
      url = url.replace(":lang", lang.toLowerCase());
      Tracker.logEvent("api", "practise-images");

      const data = await post(url, { seenIds });
      return resolveQuery(data);
    },
  });
};

export const useEmailLogin = () => {
  return useMutation({
    mutationFn: async (loginData) => {
      const data = await post(LOGIN_EMAIL_URL, loginData);
      return resolveQuery(data);
    },
  });
};

export const useKeyLogin = () => {
  return useMutation({
    mutationFn: async ({ username, key, schoolId }) => {
      Tracker.logEvent("api", "login-one-time-key");
      const data = await post(LOGIN_ONE_TIME_KEY, {
        username,
        key,
        schoolId,
      });

      return resolveQuery(data);
    },
  });
};

export const useLoginAAIntegration = () => {
  return useMutation({
    mutationFn: async ({ name, email, licenseType, licensesBase64, hash }) => {
      Tracker.logEvent("api", "login-aa");

      const data = await post(LOGIN_AAINTEGRATION_URL, {
        name,
        email,
        licenseType,
        licensesBase64,
        hash,
      });

      return resolveQuery(data);
    },
  });
};

export const useLoginAATeoriaIntegration = () => {
  return useMutation({
    mutationFn: async ({ challengedUrl }) => {
      Tracker.logEvent("api", "login-aa-teoria");

      const data = await post(LOGIN_AATEORIAINTEGRATION_URL, {
        url: challengedUrl,
      });

      return resolveQuery(data);
    },
  });
};

export const useLoginAutoliittoSSO = () => {
  return useMutation({
    mutationFn: async ({ authCode }) => {
      Tracker.logEvent("api", "login-autoliitto-sso");
      const data = await post(LOGIN_AUTOLIITTO_SSO_URL, { authCode });

      return resolveQuery(data);
    },
  });
};

export const useLoginAutoliittoIntegration = () => {
  return useMutation({
    mutationFn: async ({ externalUserId, hash, externalTime }) => {
      Tracker.logEvent("api", "login-autoliitto");
      const data = await post(LOGIN_AUTOLIITTO_URL, {
        externalUserId,
        hash,
        externalTime,
      });

      return resolveQuery(data);
    },
  });
};

export const useSendBookContentStatus = () => {
  return useMutation({
    mutationFn: async ({ materialContentId, materialId }) => {
      const data = await post(BOOK_CONTENT_STATUS_URL, {
        materialContentId,
        materialId,
      });
      return resolveQuery(data);
    },
    onSuccess: () => {
      invalidateQueries(QUERY_KEY.BOOK_STATUSES);
    },
  });
};

export const useSubmitFullTest = () => {
  return useMutation({
    mutationFn: async ({ testId, answers }) => {
      let url = TEST_ANSWER_URL.replace(":testId", testId);
      const data = await post(url, answers);
      return resolveQuery(data);
    },
  });
};

export const usePracticeLiterals = () => {
  return useMutation({
    mutationFn: async ({ category, lang, seenIds }) => {
      let url = PRACTICE_LITERALS_URL.replace(
        ":category",
        category.toLowerCase()
      );
      url = url.replace(":lang", lang.toLowerCase());
      Tracker.logEvent("api", "practise-literals");

      const data = await post(url, { seenIds });
      return resolveQuery(data);
    },
  });
};

export const usePracticeSigns = () => {
  return useMutation({
    mutationFn: async ({ lang, seenIds }) => {
      let url = PRACTICE_SIGNS_URL.replace(":lang", lang.toLowerCase());
      Tracker.logEvent("api", "practise-signs");

      const data = await post(url, { seenIds });
      return resolveQuery(data);
    },
  });
};

export const usePracticeLog = () => {
  return useMutation({
    mutationFn: async ({ category, practiceType, numQuestions, numFaults }) => {
      const data = await post(PRACTICE_LOG_URL, {
        category,
        practiceType,
        numQuestions,
        numFaults,
      });
      return resolveQuery(data);
    },
  });
};

export const useEmailRecovery = () => {
  return useMutation({
    mutationFn: async ({ email, lang }) => {
      let url = USER_EMAIL_REQUEST_EMAIL_RECOVERY.replace(":lang", lang);

      const data = await post(url, { email });
      return resolveQuery(data);
    },
  });
};

export const useFeedback = () => {
  return useMutation({
    mutationFn: async ({ userName, phone, email, feedback, extra }) => {
      const data = await post(SEND_FEEDBACK, {
        userName,
        phone,
        email,
        feedback,
        extra,
      });

      return resolveQuery(data);
    },
  });
};

export const useAddTeacher = () => {
  return useMutation({
    mutationFn: async ({ schoolId, email, userName, lang, force }) => {
      let url = ADD_TEACHER_WITH_EMAIL;
      url = url.replace(":schoolId", schoolId);
      Tracker.logEvent("api", "add-user-with-email");

      const data = await post(url, { email, userName, lang, force });
      return resolveQuery(data);
    },
    onSuccess: () => {
      invalidateQueries(QUERY_KEY.LIST_TEACHERS);
    },
  });
};

export const useAddUserWithEmail = () => {
  return useMutation({
    mutationFn: async ({ schoolId, email, userName, lang, role, force }) => {
      let url = ADD_USER_WITH_EMAIL;
      url = url.replace(":schoolId", schoolId);
      Tracker.logEvent("api", "add-user-with-email");

      const data = await post(url, { email, userName, lang, role, force });
      return resolveQuery(data);
    },
    onSuccess: () => {
      invalidateQueries(QUERY_KEY.PENDING_CREATES);
    },
  });
};

export const useSaveProfile = () => {
  return useMutation({
    mutationFn: async (userData) => {
      let url = PROFILE_URL.replace(":userId", encodeURIComponent(userData.id));
      Tracker.logEvent("api", "save-profile");

      const data = await post(url, userData);
      return resolveQuery(data);
    },
  });
};

export const useEmailOneTimeKeys = () => {
  return useMutation({
    mutationFn: async ({ userId, emailAddress }) => {
      const url = EMAIL_ONE_TIME_KEYS;
      Tracker.logEvent("api", "email-one-time-keys");

      const data = await post(url, { userId, emailAddress });
      return resolveQuery(data);
    },
  });
};

export const useOneTimeKeyOrder = () => {
  return useMutation({
    mutationFn: async ({ schoolId, deliveryMethod }) => {
      const url = ONE_TIME_KEY_ORDERS_URL.replace(":schoolId", schoolId);
      Tracker.logEvent("api", "order-passes");

      const data = await post(url, { deliveryMethod });
      return resolveQuery(data);
    },
    onSuccess: () => {
      invalidateQueries(QUERY_KEY.LATEST_ONE_TIME_KEY_ORDER);
    },
  });
};

export const useUpdateSchoolData = () => {
  return useMutation({
    mutationFn: async ({ schoolId, schoolData }) => {
      const url = SCHOOL_DATA_URL.replace(":schoolId", schoolId);
      Tracker.logEvent("api", "set-school-data");

      const data = await post(url, schoolData);
      return resolveQuery(data);
    },
    onSuccess: () => {
      invalidateQueries(QUERY_KEY.SCHOOL_DATA);
      invalidateQueries(QUERY_KEY.EVENT_LOGS);
    },
  });
};

export const useUpdateSchoolCategorySettings = () => {
  return useMutation({
    mutationFn: async ({ schoolId, category, settings }) => {
      const url = SCHOOL_CATEGORY_SETTINGS_URL.replace(":schoolId", schoolId);

      const data = await post(url, { category, ...settings });
      return resolveQuery(data);
    },
    onSuccess: () => {
      invalidateQueries(QUERY_KEY.SCHOOL_CATEGORY_SETTINGS);
    },
  });
};

export const useQuestionReport = () => {
  return useMutation({
    mutationFn: async ({ questionType, questionId, message }) => {
      Tracker.logEvent("api", "question-report");

      const data = await post(REPORT_QUESTION_URL, {
        questionType,
        questionId,
        message,
      });

      return resolveQuery(data);
    },
  });
};

export const useTrialContact = () => {
  return useMutation({
    mutationFn: async (fields) => {
      const data = await post(CONTACT_TRIAL, { ...fields });

      return resolveQuery(data);
    },
  });
};

export const useUserConnectEmail = () => {
  return useMutation({
    mutationFn: async ({ userId, email, lang, forceConnect }) => {
      let url = USER_CONNECT_EMAIL;
      url = url.replace(":userId", encodeURIComponent(userId));
      url = url.replace(":lang", lang);
      Tracker.logEvent("api", "connect-user-with-email");
      const data = await post(url, { email, forceConnect });

      return resolveQuery(data);
    },
  });
};

export const useConnectEmail = () => {
  return useMutation({
    mutationFn: async ({ email, lang, forceConnect }) => {
      let url = CONNECT_EMAIL;
      url = url.replace(":lang", lang);
      Tracker.logEvent("api", "connect-own-user-with-email");
      const data = await post(url, { email, forceConnect });

      return resolveQuery(data);
    },
  });
};

export const useSendPollAnswer = () => {
  return useMutation({
    mutationFn: async ({ pollId, answer }) => {
      const data = await post(SEND_POLL_ANSWER, { pollId, answer });

      return resolveQuery(data);
    },
  });
};

export const useUserEmailRequestSetPassword = () => {
  return useMutation({
    mutationFn: async ({ dataToken, newPassword, locale }) => {
      let url = USER_EMAIL_REQUEST_SET_PASSWORD.replace(
        ":dataToken",
        dataToken
      );

      const data = await post(url, { newPassword, locale });

      return resolveQuery(data);
    },
  });
};

export const useAcceptAgreement = () => {
  return useMutation({
    mutationFn: async ({ agreementContentId }) => {
      const data = await post(ACCEPT_AGREEMENT_URL, { agreementContentId });

      return resolveQuery(data);
    },
  });
};

export const usePurchaseProducts = () => {
  return useMutation({
    mutationFn: async ({ filledProducts, customerData, lang }) => {
      const data = await post(PURCHASE_URL, {
        filledProducts,
        customerData,
        lang,
      });

      return resolveQuery(data);
    },
  });
};

export const useUpdatePaymentMethodId = () => {
  return useMutation({
    mutationFn: async ({ orderId, orderToken, paymentMethodId }) => {
      let url = WEBSHOP_ORDER_UPDATE_PAYMENT_METHOD_URL;
      url = url.replace(":orderId", orderId);
      url = url.replace(":token", orderToken);
      url = url.replace(":methodId", paymentMethodId);

      const data = await post(url, { paymentMethodId });
      return resolveQuery(data);
    },
  });
};

export const useLogDirectTokenLogin = () => {
  return useMutation({
    mutationFn: async ({ token }) => {
      const data = await post(LOG_DIRECT_TOKEN_LOGIN, { token });
      return resolveQuery(data);
    },
  });
};

// ADMIN
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//
export const useGetSchoolComments = (schoolId) => {
  return useQuery({
    queryKey: [QUERY_KEY.SCHOOL_COMMENTS, schoolId],
    queryFn: async () => {
      let url = SCHOOL_COMMENTS.replace(":schoolId", schoolId.toLowerCase());

      const data = await get(url);
      return resolveQuery(data);
    },
  });
};

export const useEventLogs = (page, searchTerm, targetId, targetType) => {
  return useQuery({
    queryKey: [QUERY_KEY.EVENT_LOGS, page, searchTerm, targetId, targetType],
    queryFn: async () => {
      let url = EVENT_LOGS;
      url = url.replace(":page", page);
      url = url.replace(":term", searchTerm);
      url = url.replace(":targetId", targetId || "");
      url = url.replace(":targetType", targetType || "");

      const data = await get(url);
      return resolveQuery(data);
    },
  });
};

export const useInvoices = (filters) => {
  return useQuery({
    queryKey: [QUERY_KEY.INVOICES, filters],
    queryFn: async () => {
      let url = LIST_INVOICES + "?" + queryString.stringify(filters);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: false,
  });
};

export const useUserSubscriptionPeriods = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.SUBSCRIPTION_PERIODS, userId],
    queryFn: async () => {
      const url = SEARCH_USER_SUB_PERIODS.replace(
        ":userId",
        encodeURIComponent(userId)
      );

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
  });
};

export const useUserLicenses = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.USER_LICENSES, userId],
    queryFn: async () => {
      const url = SEARCH_USER_LICENSES.replace(
        ":userId",
        encodeURIComponent(userId)
      );

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
  });
};

export const useWebshopOrdersByEmail = (userEmail) => {
  return useQuery({
    queryKey: [QUERY_KEY.WEBSHOP_ORDERS_EMAIL, userEmail],
    queryFn: async () => {
      const url = SEARCH_ORDERS + `?userEmail=${userEmail}`;

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userEmail,
  });
};

export const useEmailEvents = (email) => {
  return useQuery({
    queryKey: [QUERY_KEY.EMAIL_EVENTS, email],
    queryFn: async () => {
      const url = EMAIL_EVENTS.replace(":email", email);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
    enabled: !!email,
  });
};

export const useLoginLogs = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.LOGIN_LOGS, userId],
    queryFn: async () => {
      const url = SEARCH_USER_LOGIN_LOGS.replace(
        ":userId",
        encodeURIComponent(userId)
      );

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
  });
};

export const useAdminSearchUsers = (term) => {
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_USERS_ADMIN, term],
    queryFn: async () => {
      const url = SEARCH_USERS_ADMIN + `?term=${term}`;

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!term,
  });
};

export const useGetUserComments = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.USER_COMMENTS, userId],
    queryFn: async () => {
      let url = USER_COMMENTS.replace(
        ":userId",
        encodeURIComponent(userId.toLowerCase())
      );

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
  });
};

export const useTokenForUserIdLogin = (userId) => {
  return useQuery({
    queryKey: [QUERY_KEY.TOKEN_USER_ID_LOGIN, userId],
    queryFn: async () => {
      let url = GET_TOKEN_FOR_USER_ID.replace(
        ":userId",
        encodeURIComponent(userId)
      );

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
};

export const useTokenForUserEmailLogin = (email) => {
  return useQuery({
    queryKey: [QUERY_KEY.TOKEN_USER_EMAIL_LOGIN, email],
    queryFn: async () => {
      let url = GET_TOKEN_FOR_USER_EMAIL.replace(":email", email);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!email,
    refetchOnWindowFocus: false,
  });
};

export const useListLiteralQuestions = (category, lang, id) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_LITERAL_QUESTIONS, category, lang, id],
    queryFn: async () => {
      let url = LITERALS;
      url = url.replace(":category", category);
      url = url.replace(":lang", lang);
      url = url.replace(":id", id);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useListImageQuestions = (category, lang, id) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_IMAGE_QUESTIONS, category, lang, id],
    queryFn: async () => {
      let url = IMAGES;
      url = url.replace(":category", category);
      url = url.replace(":lang", lang);
      url = url.replace(":id", id);

      const data = await get(url);
      return resolveQuery(data);
    },
    refetchOnWindowFocus: false,
  });
};

export const useInvoiceablePeriods = (filters) => {
  return useQuery({
    queryKey: [QUERY_KEY.INVOICABLE_PERIODS, filters],
    queryFn: async () => {
      let url = LIST_INVOICEABLE_PERIODS + "?" + queryString.stringify(filters);

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: false,
  });
};

export const useSearchWebshopOrders = (term) => {
  return useQuery({
    queryKey: [QUERY_KEY.SEARCH_WEBSHOP_ORDERS, term],
    queryFn: async () => {
      const url = SEARCH_ORDERS + `?term=${term}`;

      const data = await get(url);
      return resolveQuery(data);
    },
    enabled: !!term,
  });
};

export const useClearCaches = () => {
  return useMutation({
    mutationFn: async () => {
      const data = await post(CLEAR_CACHES_URL, undefined);
      return resolveQuery(data);
    },
  });
};

export const useSearchSchools = () => {
  return useMutation({
    mutationFn: async ({ searchTerm }) => {
      const data = await post(SCHOOLS_SEARCH, { term: searchTerm });
      return resolveQuery(data);
    },
  });
};

export const useAddSchoolComment = () => {
  return useMutation({
    mutationFn: async ({ schoolId, comment }) => {
      let url = SCHOOL_COMMENTS.replace(":schoolId", schoolId.toLowerCase());

      const data = await post(url, { comment });
      return resolveQuery(data);
    },
  });
};

export const useChangeEmail = () => {
  return useMutation({
    mutationFn: async ({ userId, email }) => {
      let url = CHANGE_EMAIL.replace(":userId", encodeURIComponent(userId));

      const data = await post(url, { email });
      return resolveQuery(data);
    },
  });
};

export const useDisconnectEmail = () => {
  return useMutation({
    mutationFn: async ({ userId }) => {
      let url = DISCONNECT_EMAIL.replace(":userId", encodeURIComponent(userId));

      const data = await post(url, {});
      return resolveQuery(data);
    },
  });
};

export const useRemoveEmailPassword = () => {
  return useMutation({
    mutationFn: async ({ userId }) => {
      let url = REMOVE_EMAIL_PASSWORD.replace(
        ":userId",
        encodeURIComponent(userId)
      );

      const data = await post(url, {});
      return resolveQuery(data);
    },
  });
};

export const useRemoveLicense = () => {
  return useMutation({
    mutationFn: async ({ userId, data }) => {
      let url = REMOVE_LICENSE.replace(":userId", encodeURIComponent(userId));

      const _data = await post(url, data);
      return resolveQuery(_data);
    },
  });
};

export const useAddCategoryLicense = () => {
  return useMutation({
    mutationFn: async ({ userId, data }) => {
      let url = ADD_CATEGORY_LICENSE.replace(
        ":userId",
        encodeURIComponent(userId)
      );

      const _data = await post(url, data);
      return resolveQuery(_data);
    },
  });
};

export const useAddMaterialLicense = () => {
  return useMutation({
    mutationFn: async ({ userId, data }) => {
      let url = ADD_MATERIAL_LICENSE.replace(
        ":userId",
        encodeURIComponent(userId)
      );

      const _data = await post(url, data);
      return resolveQuery(_data);
    },
  });
};

export const useChangeOrderStatusToOk = () => {
  return useMutation({
    mutationFn: async ({ orderId }) => {
      const url = ACCEPT_WEBSHOP_ORDER.replace(":orderId", orderId);

      const data = await post(url, { orderId });
      return resolveQuery(data);
    },
  });
};

export const useAddUserComment = () => {
  return useMutation({
    mutationFn: async ({ userId, comment }) => {
      let url = USER_COMMENTS.replace(
        ":userId",
        encodeURIComponent(userId.toLowerCase())
      );

      const data = await post(url, { comment });
      return resolveQuery(data);
    },
  });
};

export const useAddSchool = () => {
  return useMutation({
    mutationFn: async ({ schoolId, schoolEmail }) => {
      const data = await post(SCHOOLS_ADD, {
        schoolId: schoolId.toLowerCase(),
        schoolEmail,
      });
      return resolveQuery(data);
    },
  });
};

export const useAddBookReference = () => {
  return useMutation({
    mutationFn: async ({ questionType, questionId, category, referenceId }) => {
      let url = BOOK_REFERENCE;
      url = url.replace(":questionType", questionType);
      url = url.replace(":questionId", questionId);
      url = url.replace(":category", category);
      url = url.replace(":referenceId", referenceId);

      const data = await post(url, {});
      return resolveQuery(data);
    },
  });
};

export const useCreateInvoices = () => {
  return useMutation({
    mutationFn: async ({ schoolIds, filters, invoiceDate, dueDate }) => {
      const data = await post(CREATE_INVOICES, {
        schoolIds,
        ...filters,
        invoiceDate,
        dueDate,
      });

      return resolveQuery(data);
    },
  });
};

export const usePrecreateOneTimeKeys = () => {
  return useMutation({
    mutationFn: async ({ schoolIdsArr, amountPerSchool }) => {
      const url = PRECRETE_ONE_TIME_KEYS;

      const data = await post(url, {
        schoolIds: schoolIdsArr,
        keyAmount: amountPerSchool,
      });

      return resolveQuery(data);
    },
  });
};

export const useExportInvoice = () => {
  return useMutation({
    mutationFn: async ({ invoiceIds }) => {
      const data = await post(EXPORT_INVOICES, { invoiceIds });

      return resolveQuery(data);
    },
  });
};

export const useModifyLiteral = () => {
  return useMutation({
    mutationFn: async ({ literalQuestion }) => {
      let url = LITERAL;
      url = url.replace(":id", literalQuestion.id);

      const data = await post(url, { literalQuestion });

      return resolveQuery(data);
    },
  });
};

export const useCallTestCrashBackend = () => {
  return useQuery({
    queryFn: async () => {
      const data = await get(TEST_CRASH_BACKEND);
      return resolveQuery(data);
    },
    enabled: false,
    retry: false
  });
}
